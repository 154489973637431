import { Injectable } from '@angular/core';
import { IneligiblePage } from '@prc-serenity-models/ineligible-page.model';
import { Origin } from '../../configs/origin.config';
import { Sites } from '../../configs/sites.config';

export interface ISite {
  name: Sites;
  logo: string;
  totalSteps: number;
  partnerLogoReverse: boolean;
  link: string;
  isLibertyAllowed: boolean;
  origin: Origin; // Used for create user origin and sherlock lead source
  headerLogoSeparatorLabel?: string;
  ineligiblePage: IneligiblePage;
  utmFunnel: string;
}

@Injectable({
  providedIn: 'root',
})
export abstract class SiteService implements ISite {
  name!: Sites;
  logo = '';
  totalSteps = 0;
  partnerLogoReverse = false;
  link = '';
  isLibertyAllowed = false;
  origin!: Origin;
  headerLogoSeparatorLabel = '';
  abstract ineligiblePage: IneligiblePage;
  abstract utmFunnel: string;
}
